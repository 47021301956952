import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`KB or DB OHS’s 6-6-6/arm, as light as needed for full range of motion`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-KBS’s (53/35)`}</p>
    <p>{`50ft KB Waiters Walk with Right Arm`}</p>
    <p>{`15-KBS’s (53/35)`}</p>
    <p>{`50ft KB Waiters Walk with Left Arm`}</p>
    <p>{`75 Double Unders`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`Tabata Hanging L Holds :20 on :10 off for 8 rounds`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      